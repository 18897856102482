
import Vue from 'vue'
import Sortable from 'sortablejs'

export default class SortableList extends Vue {
  bind(el, binding, vnode) {
    const options = {
      handle: '.sort-handle',
      animation: 150,
      direction: 'vertical' as Sortable.Direction,
      filter: '.v-row-group__header',
      onUpdate: function (event) {
        vnode.child.$emit('sorted', event)
      },
    } as Sortable.Options
    Sortable.create(el.getElementsByTagName('tbody')[0], options)
  }
}
