



































































































































































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import ListView from '@/components/list/ListView.vue'
import PageTab from '@/components/ui/PageTab.vue'
import { appendSitePrefix } from '@/utils/routeUtils'
import { vxm } from '@/store'
import SortableList from '@/components/list/SortableList.vue'
import { Debounce } from 'lodash-decorators'
import SubTab from '@/models/ui/SubTab'
import MarginType from '@/models/price/manager/MarginType'

const sortableList = new SortableList()

@Component({
  components: {
    ListView,
    PageTab,
  },
  directives: {
    sortableList,
  },
})
export default class List extends Vue {
  private appendSitePrefix = appendSitePrefix

  private marginTypeTabsLabels = [
    'c:price-manager-tab:Cost prices',
    'c:price-manager-tab:Sales prices',
    'c:price-manager-tab:Customer prices',
    'c:price-manager-tab:Default discounts',
  ]

  private marginTypeTabsRoutes = ['discount', 'profit', 'customer_discount', 'default']

  private marginTypeTab = 0

  private marginTypeDiscount = 0
  private marginTypeProfit = 1
  private marginTypeCustomerDiscount = 2
  private marginTypeDefault = 3

  private marginSubTypeTabsRoutes = ['rules', 'products']

  private marginSubTypeTab = 0

  private marginSubTypeRules = 0
  private marginSubTypeProducts = 1

  private productsType = false

  private topActions = []
  private selectActions = []
  private rowActions = []

  private featureUsePriceBonus = false

  private showOnlyNotInStockProducts = false

  private sortEnabled = false
  private saveSortOrderDisabled = true

  private componentKeyDate = 0

  private productPriceMarginItems = []

  private itemsPerPage = 30
  private oldQuery = {}

  private chooseCustomerDialog = false
  private newProductPriceMarginCustomer = null
  private searchCustomer = null
  private customersFoundItems = []
  private customerSearchLoading = false

  private showNetPriceSuppliersDialog = false
  private netPriceSuppliersNames = null

  private chooseSupplierDialog = false
  private newProductPriceMarginSupplier = null
  private notNetPriceSuppliers = []

  private customerIdSet = null
  private supplierIdSet = null
  private customerNameSet = null
  private customerNameSetPostfix = null
  private supplierNameSet = null

  private get headers() {
    if (this.marginTypeTab === this.marginTypeCustomerDiscount && !this.customerIdSet) {
      return this.customersOverviewHeaders()
    } else if (this.marginTypeTab === this.marginTypeDiscount && !this.supplierIdSet) {
      return this.suppliersOverviewHeaders()
    } else {
      return this.generalHeaders()
    }
  }

  private get enableSearch() {
    return !this.sortEnabled
  }

  private get enableFilters() {
    return !this.sortEnabled && !this.isCustomerDiscountWithoutCustomerSet && !this.isDiscountWithoutSupplierSet
  }

  private get hideDefaultFooter() {
    return this.sortEnabled || this.isCustomerDiscountWithoutCustomerSet || this.isDiscountWithoutSupplierSet
  }

  private customersOverviewHeaders() {
    const headers = []

    headers.push({
      text: 'c:price-manager-header:Name',
      value: 'name',
      filter: {},
      sortable: false,
    })

    headers.push({
      text: 'c:price-manager-header:Customer number',
      value: 'customerNumber',
      filter: {},
      sortable: false,
    })

    headers.push({
      text: 'c:price-manager-header:Eontyre site',
      value: 'shopId',
      filter: {},
      sortable: false,
    })

    headers.push({
      text: 'c:price-manager-header:Rules',
      value: 'numberOfRules',
      filter: {
        disable: true,
      },
      sortable: false,
    })

    headers.push({
      text: 'c:price-manager-header:Actions',
      value: 'actions',
      filter: {},
      sortable: false,
    })

    return headers
  }

  private suppliersOverviewHeaders() {
    const headers = []

    headers.push({
      text: 'c:price-manager-header:Name',
      value: 'name',
      filter: {},
      sortable: false,
    })

    headers.push({
      text: 'c:price-manager-header:Rules',
      value: 'numberOfRules',
      filter: {
        disable: true,
      },
      sortable: false,
    })

    headers.push({
      text: 'c:price-manager-header:Actions',
      value: 'actions',
      filter: {},
      sortable: false,
    })

    return headers
  }

  private generalHeaders() {
    const headers = []

    headers.push({
      text: 'c:price-manager-header:ID',
      value: 'id',
      filter: {},
      sortable: false,
    })

    headers.push({
      text: 'c:price-manager-header:Name',
      value: 'name',
      filter: {},
      sortable: false,
    })

    if (this.productsType) {
      headers.push({
        text: 'c:price-manager-header:Product ID',
        value: 'productId',
        filter: {},
        sortable: false,
      })
    }

    headers.push({
      text: 'c:price-manager-header:Value',
      value: 'valueFormatted',
      filter: {
        disable: true,
      },
      sortable: false,
    })

    headers.push({
      text: 'c:price-manager-header:Period',
      value: 'periodFormatted',
      filter: {
        disable: true,
      },
      sortable: false,
    })

    if (this.marginTypeTab === this.marginTypeProfit && this.featureUsePriceBonus) {
      headers.push({
        text: 'c:price-manager-header:Bonus',
        value: 'bonusFormatted',
        filter: {},
        sortable: false,
      })
    }
    if (this.marginTypeTab !== this.marginTypeDiscount) {
      headers.push({
        text: 'c:price-manager-header:Base Price',
        value: 'basePriceFormatted',
        filter: { disable: true },
        sortable: false,
      })
    }

    headers.push({
      text: 'c:price-manager-header:Actions',
      value: 'actions',
      filter: {},
      sortable: false,
    })

    return headers
  }

  // When params change, pass them as query to ListView, which will
  // cause it include them in browser URL, together with its own "url persistent" params.
  private get query() {
    const data = {
      showOnlyNotInStockProducts: this.showOnlyNotInStockProducts,
      customerId: this.customerIdSet,
      supplierId: this.supplierIdSet,
    }
    if (!this.productsType) {
      delete data.showOnlyNotInStockProducts
    }
    if (this.isCustomerDiscountWithoutCustomerSet) {
      delete data.customerId
    }
    if (this.isDiscountWithoutSupplierSet) {
      delete data.supplierId
    }

    return data
  }

  private get mainUrl() {
    const marginTypeTab = this.marginTypeTabsRoutes[this.marginTypeTab]
    const marginSubTypeTab = this.marginSubTypeTabsRoutes[this.marginSubTypeTab]

    return '/v4/site/price/manager/' + marginTypeTab + '/' + marginSubTypeTab
  }

  // URL to fetch data from backend
  // We include the query params from above, and ListView will append its own if needed (sorting etc)
  private get url() {
    let mainUrl = this.mainUrl

    if (this.isCustomerDiscountWithoutCustomerSet) {
      mainUrl += '/customers'
    } else if (this.isDiscountWithoutSupplierSet) {
      mainUrl += '/suppliers'
    }

    return mainUrl
  }

  private get listComponentKey() {
    return this.marginTypeTab + ',' + this.marginSubTypeTab + ',' + this.componentKeyDate
  }

  private get groupBy() {
    let result = null
    if (this.isDiscountWithAllSuppliers) {
      result = 'supplier.name'
    } else if (this.isCustomerDiscountWithAllCustomers) {
      result = 'customer.name'
    }

    return result
  }

  private get showSelect() {
    let result = true
    if (this.isCustomerDiscountWithoutCustomerSet) {
      result = false
    } else if (this.isDiscountWithoutSupplierSet) {
      result = false
    }

    return result
  }

  private get isCustomerDiscountWithCustomerSet() {
    return this.marginTypeTab === this.marginTypeCustomerDiscount && this.customerIdSet && this.customerIdSet !== -1
  }

  private get isCustomerDiscountWithAllCustomers() {
    return this.marginTypeTab === this.marginTypeCustomerDiscount && this.customerIdSet && this.customerIdSet === -1
  }

  private get isCustomerDiscountWithoutCustomerSet() {
    return this.marginTypeTab === this.marginTypeCustomerDiscount && !this.customerIdSet
  }

  private get isDiscountWithSupplierSet() {
    return this.marginTypeTab === this.marginTypeDiscount && this.supplierIdSet && this.supplierIdSet !== -1
  }

  private get isDiscountWithAllSuppliers() {
    return this.marginTypeTab === this.marginTypeDiscount && this.supplierIdSet && this.supplierIdSet === -1
  }

  private get isDiscountWithoutSupplierSet() {
    return this.marginTypeTab === this.marginTypeDiscount && !this.supplierIdSet
  }

  private mounted() {
    this.rowActions = [
      {
        id: 'show',
        click: (_parent, item) => {
          if (this.marginTypeTab === this.marginTypeDiscount) {
            if (this.supplierIdSet) {
              this.navigateToEdit(item.id)
            } else {
              this.supplierIdSet = item.id
              this.setTopActions()
              return false
            }
          } else if (this.marginTypeTab === this.marginTypeProfit) {
            this.navigateToEdit(item.id)
          } else if (this.marginTypeTab === this.marginTypeCustomerDiscount) {
            if (this.customerIdSet) {
              this.navigateToEdit(item.id)
            } else {
              this.customerIdSet = item.id
              this.setTopActions()
              return false
            }
          } else if (this.marginTypeTab === this.marginTypeDefault) {
            this.navigateToEdit(item.id)
          } else {
            return false
          }
        },
        label: 'c:common:Show',
      },
    ]
  }

  private navigateToNew(marginType: string, referenceId: number = null) {
    this.$router.push({
      name: 'Price/Manager/New',
      params: { marginType: marginType, referenceId: '' + referenceId },
    })
  }

  private navigateToEdit(id) {
    this.$router.push({
      name: 'Price/Manager/Edit',
      params: { id: id },
    })
  }

  private setTopActions() {
    const topActions = []
    const selectActions = []

    topActions.push({
      id: 'new',
      click: () => {
        if (this.marginTypeTab === this.marginTypeProfit) {
          this.navigateToNew(MarginType.Profit)
        } else if (this.isDiscountWithoutSupplierSet || this.isDiscountWithAllSuppliers) {
          this.chooseSupplierDialog = true
        } else if (this.isDiscountWithSupplierSet) {
          this.navigateToNew(MarginType.Discount, this.supplierIdSet)
        } else if (this.isCustomerDiscountWithoutCustomerSet || this.isCustomerDiscountWithAllCustomers) {
          this.chooseCustomerDialog = true
        } else if (this.isCustomerDiscountWithCustomerSet) {
          this.navigateToNew(MarginType.CustomerDiscount, this.customerIdSet)
        } else if (this.marginTypeTab === this.marginTypeDefault) {
          this.navigateToNew(MarginType.Default)
        } else {
          return false
        }
      },
    })

    if (
      (this.marginTypeTab !== this.marginTypeDiscount && this.marginTypeTab !== this.marginTypeCustomerDiscount) ||
      (this.marginTypeTab === this.marginTypeDiscount && this.supplierIdSet) ||
      (this.marginTypeTab === this.marginTypeCustomerDiscount && this.customerIdSet)
    ) {
      selectActions.push({
        id: 'delete',
        dense: true,
      })

      if (!this.productsType) {
        topActions.push({
          id: 'sort',
          label: 'c:price-manager:Change sort order',
          click: () => {
            this.sortEnabled = true
          },
          disabled: this.sortEnabled,
        })
      }
    }

    this.topActions = topActions
    this.selectActions = selectActions
  }

  private onData(data) {
    this.productPriceMarginItems = data.items

    if (this.isCustomerDiscountWithCustomerSet) {
      this.customerNameSet = data.responseData.customer.name
      this.customerNameSetPostfix =
        this.$t('c:common:Customer number') + ': ' + data.responseData.customer.customerNumber
      if (data.responseData.customer.shopId) {
        this.customerNameSetPostfix +=
          ', ' + this.$t('c:common:Eontyre site') + ': ' + data.responseData.customer.shopId
      }
    } else if (this.isCustomerDiscountWithAllCustomers) {
      this.customerNameSet = this.$t('c:common:All')
      this.customerNameSetPostfix = null
    } else if (this.isDiscountWithSupplierSet) {
      this.supplierNameSet = data.responseData.supplier.name
    } else if (this.isDiscountWithAllSuppliers) {
      this.supplierNameSet = this.$t('c:common:All')
    }
  }

  @Watch('$route.params.marginType')
  private onMarginTypeChanged() {
    this.checkMarginType()
  }

  private checkMarginType(calledFromCreated = false) {
    if (!calledFromCreated) {
      this.supplierIdSet = null
      this.customerIdSet = null
    }
    this.cancelSortOrder()
    this.setTopActions()
  }

  @Watch('$route.params.marginSubType')
  private checkMarginSubType() {
    if (this.marginSubTypeTab === this.marginSubTypeProducts) {
      this.productsType = true
      this.showOnlyNotInStockProducts = this.$route.query.showOnlyNotInStockProducts === 'true'
    } else {
      this.productsType = false
    }

    this.cancelSortOrder()
    this.setTopActions()
  }

  private backToGroups() {
    this.customerIdSet = null
    this.supplierIdSet = null
    this.setTopActions()
  }

  private onTabChange(marginTypeIndex) {
    this.updateRoute(marginTypeIndex, null)
  }

  private onSubTabChange(marginSubTypeIndex) {
    this.updateRoute(null, marginSubTypeIndex)
  }

  private updateRoute(marginTypeIndex, marginSubTypeIndex) {
    if (marginTypeIndex !== null) {
      this.marginTypeTab = marginTypeIndex
    }
    if (marginSubTypeIndex !== null) {
      this.marginSubTypeTab = marginSubTypeIndex
    }
    const marginTypeTab = this.marginTypeTabsRoutes[this.marginTypeTab]
    const marginSubTypeTab = this.marginSubTypeTabsRoutes[this.marginSubTypeTab]
    this.$router.push({
      name: 'Price/Manager/List',
      params: { marginType: marginTypeTab, marginSubType: marginSubTypeTab },
    })
  }

  private sorted() {
    this.saveSortOrderDisabled = false
  }

  private saveSortOrder() {
    const payload = []
    const elems = document.getElementsByClassName('sort-item-id')
    let sortOrder = elems.length
    for (let i = 0; i < elems.length; i++) {
      const id = elems[i].innerHTML.trim()
      payload.push({ id, sortOrder })
      sortOrder--
    }

    this.$axios
      .post(this.mainUrl + '/many/sortOrders', { items: payload })
      .then((_response) => {
        this.sortEnabled = false
      })
      .catch((err) => {
        if (err?.response?.data?.error) {
          vxm.alert.error({
            content: err.response.data.error.message,
            title: this.$t('c:common:Error') as string,
          })
        }
      })
  }

  private cancelSortOrder() {
    this.sortEnabled = false
    this.saveSortOrderDisabled = true
  }

  @Watch('sortEnabled')
  private async onSortEnabled() {
    let newQuery: any
    if (this.sortEnabled) {
      newQuery = Object.assign({}, this.$route.query)
      newQuery.perPage = -1 + ''
      newQuery.search = ''
      this.oldQuery = Object.assign({}, this.$route.query)
    } else {
      newQuery = this.oldQuery
    }
    await this.$router.push({ query: newQuery })

    this.componentKeyDate = new Date().getTime()

    this.setTopActions()
  }

  private addSupplierProductPriceMargin() {
    this.navigateToNew(MarginType.Discount, this.newProductPriceMarginSupplier)
  }

  private addCustomerProductPriceMargin() {
    this.navigateToNew(MarginType.CustomerDiscount, this.newProductPriceMarginCustomer)
  }

  @Debounce(500)
  @Watch('searchCustomer')
  private onCustomerSearchChanged(val) {
    // On first time edit opening do not make a search since we have the data prefilled
    if (val === null || val.length < 3) {
      return
    }

    this.$axios
      .get('/v3/customers/search?query=' + encodeURIComponent(val))
      .then((response) => {
        this.customersFoundItems = response.data.data.customers
      })
      .catch((error) => {
        error.response.data.errors.forEach((v) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })
      .finally(() => {
        this.customerSearchLoading = false
      })
  }

  private get subTabs() {
    const result = []
    result.push(new SubTab({ text: 'c:price-manager-tab:Rules' }))
    result.push(new SubTab({ text: 'c:price-manager-tab:Products' }))
    return result
  }

  private created() {
    this.$axios
      .get(this.mainUrl + '/initial-data')
      .then((response) => {
        this.featureUsePriceBonus = response.data.featureUsePriceBonus
        this.notNetPriceSuppliers = response.data.notNetPriceSuppliers || []

        const netPriceSuppliers = response.data.netPriceSuppliers || []
        if (netPriceSuppliers.length > 0) {
          this.netPriceSuppliersNames = netPriceSuppliers
            .map((netPriceSupplier) => netPriceSupplier.name)
            .join('<br/>')
        }
      })
      .catch((err) => {
        if (err?.response?.data?.error) {
          vxm.alert.error({
            content: err.response.data.error.message,
            title: this.$t('c:common:Error') as string,
          })
        }
      })

    window.onpopstate = () => {
      this.loadOnCreate()
    }

    this.loadOnCreate()
  }

  private loadOnCreate() {
    this.marginTypeTab = this.marginTypeTabsRoutes.indexOf(this.$route.params.marginType)
    this.marginSubTypeTab = this.marginSubTypeTabsRoutes.indexOf(this.$route.params.marginSubType)

    if (this.$route.query.customerId !== undefined) {
      this.customerIdSet = parseInt(this.$route.query.customerId.toString())
    } else {
      this.customerIdSet = null
    }

    if (this.$route.query.supplierId !== undefined) {
      this.supplierIdSet = parseInt(this.$route.query.supplierId.toString())
    } else {
      this.supplierIdSet = null
    }

    this.checkMarginType(true)
    this.checkMarginSubType()
  }
}
