export default class SubTab {
  public text: string | null
  public textClassNormal: string | null
  public textClassSelected: string | null
  public icon: string | null
  public iconColorNormal: string | null
  public iconColorSelected: string | null
  public backgroundNormalColor = 'primary'
  public translateText = true

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }

    this.text = data.text ? (data.text as string) : null
    this.textClassNormal = data.textClassNormal ? (data.textClassNormal as string) : null
    this.textClassSelected = data.textClassSelected ? (data.textClassSelected as string) : this.textClassNormal
    this.icon = data.icon ? (data.icon as string) : null
    this.iconColorNormal = data.iconColorNormal ? (data.iconColorNormal as string) : null
    this.iconColorSelected = data.iconColorSelected ? (data.iconColorSelected as string) : this.iconColorNormal
    if (data.backgroundNormalColor) {
      this.backgroundNormalColor = data.backgroundNormalColor as string
    }
    if (data.translateText) {
      this.translateText = data.translateText as boolean
    }
  }

  public clone(): SubTab {
    const result = new SubTab()
    Object.assign(result, this)
    return result
  }
}
